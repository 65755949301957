<template>
  <b-card-body>
    <div class="item-features">
      <div class="row text-center">
        <div
          v-for="(step, i) in steps"
          :key="`step-${i}`"
          :class="`col-12 col-md-${12 / steps.length} mb-${12 / steps.length} mb-md-0`"
        >
          <div class="w-75 mx-auto">
            <feather-icon
              :class="`text-${step.iconVariant}`"
              :icon="step.icon"
              size="35"
            />
            <h4 class="mt-2 mb-1">
              {{ step.title }}
            </h4>
            <p class="card-text">
              {{ step.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<script>
import { BCardBody } from 'bootstrap-vue'

export default {
  components: { BCardBody },
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>

</style>
