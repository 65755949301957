import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import affiliateModel from './affiliateModel'
import countryModel from '../common/models/countryModel'

const {
  fetchList,
  fetchAllChildren,
  fetchAll,
  fetchOne,
  fetchOneWithMeta,
  create,
  update,
  deleteMany,
} = useModelStoreModule(affiliateModel)

const {
  fetchList: fetchCountries,
} = useModelStoreModule(countryModel)

const fetchCommissions = (ctx, id) => fetchAllChildren(ctx, id, 'commissions')

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    fetchOneWithMeta,
    create,
    update,
    deleteMany,

    fetchCountries,
    fetchCommissions,
  },
}
