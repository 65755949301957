<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5 v-t="`Filters`" />
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <b-form-group>
            <label v-t="`modules.affiliates.inputs.orders_from_date`" />
            <b-form-datepicker
              v-model="localFromDate"
              :min="null"
              :max="localToDate ? localToDate :today"
              :locale="$i18n.locale"
              menu-class="w-100"
              calendar-width="100%"
              :placeholder="$t('modules.affiliates.inputs.orders_from_date:placeholder')"
              today-button
              reset-button
              close-button
              :label-today-button="$t('Today')"
              :label-reset-button="$t('Reset')"
              :label-close-button="$t('Close')"
              @input="(val) => $emit('update:fromDateFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <b-form-group>
            <label v-t="`modules.affiliates.inputs.orders_to_date`" />
            <b-form-datepicker
              v-model="localToDate"
              :min="localFromDate ? localFromDate : null"
              :max="today"
              :locale="$i18n.locale"
              menu-class="w-100"
              calendar-width="100%"
              :placeholder="$t('modules.affiliates.inputs.orders_to_date:placeholder')"
              today-button
              reset-button
              close-button
              :label-today-button="$t('Today')"
              :label-reset-button="$t('Reset')"
              :label-close-button="$t('Close')"
              @input="(val) => $emit('update:toDateFilter', val)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <i-input
            v-model="localOrderStatusFilter"
            type="select"
            :title="$t('Order Status')"
            :placeholder="$t('modules.affiliates.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="orderStatusOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:orderStatusFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="12"
          class="mb-md-0 mb-2"
        >
          <i-input
            v-model="localCommissionStatusFilter"
            type="select"
            :title="$t('Commission Status')"
            :placeholder="$t('modules.affiliates.inputs.commission_status:placeholder')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="commissionStatusOptions"
            :reduce="val => val.value"
            class="invoice-filter-select mb-0"
            @input="(val) => $emit('update:commissionStatusFilter', val)"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </i-input>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import { orderStatusOptions, commissionStatusOptions } from '@core/utils/constants'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    // categoryFilter: {
    //   type: [Number, null],
    //   default: null,
    // },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    orderStatusFilter: {
      type: String,
      default: null,
    },
    commissionStatusFilter: {
      type: String,
      default: null,
    },
    fromDateFilter: {
      type: String,
      default: null,
    },
    toDateFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    // 15th two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    // 15th in two months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)

    return {
      today,
      min: minDate,
      max: maxDate,

      localOrderStatusFilter: this.orderStatusFilter,
      localCommissionStatusFilter: this.commissionStatusFilter,
      localFromDate: this.fromDateFilter,
      localToDate: this.toDateFilter,
    }
  },
  watch: {
    orderStatusFilter(newValue) {
      this.localOrderStatusFilter = newValue
    },
    commissionStatusFilter(newValue) {
      this.localCommissionStatusFilter = newValue
    },
    fromDateFilter(newValue) {
      this.localFromDate = newValue
    },
    toDateFilter(newValue) {
      this.localToDate = newValue
    },
  },
  setup() {
    return {
      orderStatusOptions,
      commissionStatusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
