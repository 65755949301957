import { computed, ref } from 'vue'
import { catchException, toast } from '@core/utils/utils'
import router from '@/router'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useModelUpdate(
  storeModuleName,
  refs,
  itemData,
  onSaveSuccess,
  // validationErrorsResolver,
  editRoute = null,
  exitRoute = null,
  exitCallback = null,
) {
  const { t } = useI18nUtils()
  const validationErrors = ref({})
  const itemId = computed(() => itemData.value.id)
  const isNewItem = computed(() => !itemId.value)

  function exit() {
    if (exitCallback) {
      exitCallback()
    } else if (exitRoute) {
      router.push({ name: exitRoute })
    }
  }

  function fetch() {
    return store.dispatch(`${storeModuleName}/fetchOne`, itemId.value)
      .then(data => {
        itemData.value = {
          ...itemData.value,
          ...data,
        }
        return data
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          exit()
        } else if (err.response && err.response.status === 401) {
          toast('warning', t('Not Authenticated'))
        } else {
          toast('danger', err.message)
        }
      })
      .finally(() => {
        //
      })
  }
  function performSave(shouldExit = false, resolve = null, reject = null, always = null) {
    const action = isNewItem.value ? `${storeModuleName}/create` : `${storeModuleName}/update`
    return store.dispatch(action, itemData.value)
      .then(data => {
        const wasNew = isNewItem.value
        if (wasNew) {
          itemData.value.id = data.id
        }

        onSaveSuccess(data)

        toast('success', t('message.operation_success'))

        if (shouldExit) {
          exit()
        } else if (wasNew) {
          if (editRoute) {
            router.push({ name: editRoute, params: { id: itemData.value.id } })
          }
        }

        // Clear validation errors
        validationErrors.value = {}

        if (resolve) {
          resolve(data)
        }
      })
      .catch(err => {
        catchException(err)
        if (err?.response?.status === 422) {
          const responseData = err.response.data

          // validationErrors.value = validationErrorsResolver(responseData.errors) // Validation resolved already
          validationErrors.value = responseData.errors
          toast('warning', t('message.generic_validation_error'), responseData?.message)
        } else if ((err?.response?.status === 400) && (err?.response?.data?.message)) {
          toast('danger', err?.response?.data?.message)
        } else {
          toast('danger', t('message.operation_failed'))
        }

        if (reject) {
          reject(err)
        }
      })
      .finally(() => {
        if (always) {
          always()
        }
      })
  }

  /**
   * @param {string[]} failedWithErrors
   * @param myRefs
   */
  const focusOnTabsOfErrors = (failedWithErrors, myRefs) => {
    const processedInputs = []
    failedWithErrors.forEach(inputName => {
      const nameParts = inputName.split('_')
      // Check if it is a translatable input
      if (nameParts.length >= 2) {
        const lang = nameParts[nameParts.length - 1]
        const baseInputName = inputName.replace(`_${lang}`, '')

        if (processedInputs.includes(baseInputName)) {
          return
        }

        // eslint-disable-next-line no-prototype-builtins
        if (myRefs.hasOwnProperty(baseInputName)) {
          // console.log('found input with validation error', baseInputName, refs[baseInputName])
          myRefs[baseInputName].selectTabOfLang(lang)
        }
        processedInputs.push(baseInputName)
      }
    })
  }

  const save = (shouldExit = false, resolve = null, reject = null, always = null) => {
    const myRefs = refs.value.value
    myRefs.refFormObserver.validateWithInfo().then(({ isValid, errors, fields }) => {
      if (isValid) {
        performSave(shouldExit, resolve, reject, always)
      } else {
        toast('warning', t('message.fill_required_fields'))
        const failedFields = Object.keys(fields).filter(key => fields[key].failed)
        focusOnTabsOfErrors(failedFields, myRefs)
        if (reject) {
          reject()
        }
      }
    })
  }

  return {
    validationErrors,
    fetch,
    save,
    exit,

    performSave,
    focusOnTabsOfErrors,
  }
}
